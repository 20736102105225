<template>
	<aside v-if="bannerList.length > 0" class="sugotoku-top-banner">
		<h2 class="about-column__title">頭痛ーるからのお知らせ</h2>
		<div class="sugotoku-top-banner__inner swiper-container" id="js_swiper">
			<ul class="sugotoku-top-banner__list swiper-wrapper">
				<!-- TODO: 暫定的にitem.link_urlにignoreBunnerUrlの文字列が部分一致したらバナーを表示しないようにしている。管理ツールで特集用の掲載枠を作って出しわける方が理想？ -->
				<li
					v-for="(item, index) in bannerList"
					class="sugotoku-top-banner__item swiper-slide"
					:key="`sugotoku-top-banner-${index}`"
					v-show="ignoreBunnerUrl === '' || item.link_url.indexOf(ignoreBunnerUrl) === -1"
				>
					<img class="sugotoku-top-banner__item--image" :src="item.image" @click="routerLink(item.link_url)">
					<img v-if="item.new_flag === '1'" class="sugotoku-top-banner__item--new" src="../../assets/img/badge_n.png">
				</li>
			</ul>
			<div class="swiper-pagination" id="js_swiper-pagination"></div>
		</div>
	</aside>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'
import { SET_MENU_ITEM } from '../../store/modules/common/mutation-types'

// ライブラリ
import Swiper from 'swiper'
import cmnConst from '@/assets/js/constant.js'
import moment from 'moment'
moment.locale('ja')

export default {
	name: 'SugotokuTopBanner',
	data () {
		return {
			bannerList: []
		}
	},
	props: {
		ignoreBunnerUrl: {
			type: String,
			default: ''
		}
	},
	mounted () {
		// NOTE: バナーデータを取得していなければAPI通信してバナー設定、取得済みならバナー設定のみ
		// console.log('pre menuItem: ', this.menuItem)
		if (this.menuItem === null) {
			// NOTE:バナーデータ取得処理
			this.SET_MENU_ITEM({
				user_id: this.userId,
				user_token: this.userToken
			}).then(res => {
				// console.log('menuItem: ', this.menuItem)
				this.setTopBanner()
			}).catch(error => {
				// NOTE: エラー時はなにもしない
				console.log(error)
			})
		} else {
			this.setTopBanner()
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['userId', 'userToken', 'menuItem', 'isAndroid'])
	},
	methods: {
		// map Vuex actions
		...mapActions('common', [SET_MENU_ITEM]),

		/**
		 * TOPページバナー設定処理
		 */
		setTopBanner () {
			// NOTE: レスポンスデータからスゴ得TOPに表示するデータ（banner_type: 'sugotoku_top'）を取得
			// NOTE: APIはperiod_startが７日前のものとperiod_endがまだ終了していないものを返す
			// NOTE: target_osでの出しわけは現状androidとiosのみで未設定は管理ツールでできないようになっている
			const now = moment().unix()
			const targetOs = this.isAndroid ? 'android' : 'ios'
			this.bannerList = this.menuItem.records.filter(item => item.banner_type === 'sugotoku_top' && item.target_user.includes('4') && now > item.period_start.sec && item.target_os.includes(targetOs))
			// NOTE 表示順をorder順(昇順)に並び替える
			this.bannerList.sort((a, b) => {
				if (a.order < b.order) return -1
				if (a.order > b.order) return 1
				return 0
			})
			// console.log('bannerList: ', this.bannerList)

			// スワイパーの初期化
			this.$nextTick(() => {
				this.initSwiper()
			})
		},

		/**
		 * ルーティング用リンク
		 */
		routerLink (path) {
			// NOTE: 管理ツールで登録すると&が&amp;になってしまうので置換
			path = path.replace(/&amp;/g, '&')
			if (this.isAndroid && path.startsWith('dcmdam:')) {	// android且つURLがドコモURLスキームの場合
				window.location.href = path
			} else {
				// NOTE: 管理ツールのリンクURLは?_path以降の文字列が設定されている（例 special&_specialType=hogehoge）
				const params = new URL(`${cmnConst.BACKEND_URL}?_path=${path}`).searchParams
				const query = { url: '' }

				params.forEach((value, key) => {
					if (key === '_path') {
						query.url = `${cmnConst.BACKEND_URL}?_path=${value}`
					} else {
						query[key] = value
					}
				})

				// CHANGE: スゴ得ブラウザバック問題対応のため$router.pushをname+queryで行うよう修正
				this.$router.push({ name: 'Cpsite', query: query }).catch(error => {
					if (error.name !== 'NavigationDuplicated') throw error
				})
				// this.$router.push({ path: `${this.sugotokuDir}/cpsite.html?url=${cmnConst.BACKEND_URL}?_path=${path}` }).catch(error => {
				// 	if (error.name !== 'NavigationDuplicated') throw error
				// })
			}
		},

		/**
		 * Swiperの処理
		 */
		initSwiper () {
			const swiperConstructor = () => {
				return new Swiper('#js_swiper', {
					autoplay: {
						delay: 3000
					},
					loop: false,
					spaceBetween: 10,
					pagination: {
						el: '#js_swiper-pagination',
						type: 'bullets',
						clickable: true
					}
				})
			}
			swiperConstructor()
		}
	},
	beforeDestroy () {
	}
}
</script>

<style lang="scss" scoped>
@import '~swiper/css/swiper.min.css';
@import "../../assets/sass/variable";

.sugotoku-top-banner {
	margin-top: $spacing-20;
	padding: 0 $spacing-10;

	&__list {
		margin: $spacing-6 0;
		padding: 0;
	}

	&__item {
		position: relative;
		list-style: none;

		&--image {
			width: 100%;
			vertical-align: middle;
		}

		&--new {
			position: absolute;
			width: 25px;
			top: 4px;
			right: 4px;
		}
	}

	.swiper-wrapper {
		align-items: center;
	}

	.swiper-pagination {
		position: static;
	}

	/deep/ .swiper-pagination-bullet {
		background: $button-secondary;
		opacity: 1;
	}

	/deep/ .swiper-pagination-bullet-active { background: $menu-icon; }
}

</style>
