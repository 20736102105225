<template>
<div class="special-container" id="js_special_container">
	<!-- NOTE: Special.vueは特集ページのデザイン通りのページ作成で使用 -->
	<article class="special">
		<!-- TODO: ここから次の特集で子コンポーネント化 -->
		<div class="special-unique">
			<h1 class="special-unique__header">
				<img src="@/assets/img/special_header_temperatureDifference.png" alt="頭痛ーる特集 寒暖差が原因でストレスに！ 予防策やおすすめ食材を紹介">
			</h1>

			<p class="special-unique__description">
				11月になると少しずつ寒い日が増えてきます。寒暖差が大きくなると体のストレス反応が起こるんです。それはどうしてなのか、原因と改善策を紹介します。
			</p>

			<section class="about-column special-unique__column">
				<h2 class="about-column__title">頭痛ーるコラム</h2>
				<!-- NOTE: errorMessageに何か文言があればエラーとみなしてエラー文言表示 -->
				<p v-if="errorMessage !== ''" class="about-column__error"> {{ errorMessage }}</p>
				<ul v-else class="about-column__list">
					<li
						v-for="columnItem in columnList"
						:class="[
							'about-column__item',
							{ 'is-active': columnItem.isActive }
						]"
						:key="`columnItem-${columnItem.id}`"
					>
						<span
							@click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=columnDetail`, _detailId: columnItem.id } })"
							class="about-column__anchor"
							@touchstart="columnItem.isActive = true"
							@touchend="columnItem.isActive = false"
						>
							<figure class="about-column__thumbnail">
								<img v-if="columnItem.source_url" :src="`${columnItem.source_url}`" :alt="`${columnItem.alt_text}`" :class="{ 'is-ratio-height': columnItem.ratio }" />
								<img v-else src="@/assets/img/column_noimage.jpg" />
							</figure>
							<div class="about-column__contents">
								<h3 class="about-column__contents-title">{{ columnItem.title }}</h3>
								<span class="about-column__contents-readmore">続きを読む</span>
							</div>
						</span>
					</li>
				</ul>
				<span v-show="errorMessage === ''" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=columnTop` } })" class="about-column__seemore">もっと見る</span>
			</section>
		</div>
		<!-- TODO: ここまで次の特集で子コンポーネント化 -->

		<!-- NOTE: 固定エリアは現状Webviewでの表示を想定していないものしかないので一旦非表示 -->
		<div class="special-common" v-if="!isWebview">
			<div class="about-rightnow__login">
				<p class="about-rightnow__login--text">その頭痛や体調不良、事前に予測できるかも!?</p>
				<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="about-rightnow__login--button" id="trackingAppDownloadBtn05">今すぐアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
				<a v-else @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=weatherGraph` } })" class="about-rightnow__login--button">今すぐ利用する</a>
			</div>

			<sugotoku-top-banner :ignore-bunner-url="specialType" />

			<!-- TOPへのリンク -->
			<div class="special-common__back-button--wrapper">
				<p class="special-common__back-button" @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })">TOPにもどる</p>
			</div>
		</div>
	</article>
</div>
</template>

<script>
import cmnConst from '@/assets/js/constant.js'

// Vuex
import { mapActions, mapGetters } from 'vuex'
import { SET_COLUMN_LIST_GENERIC } from '../store/modules/column/mutation-types'

// Compornents
import SugotokuTopBanner from '@/components/Organisms/SugotokuTopBanner'

export default {
	name: 'Special',
	components: {
		SugotokuTopBanner
	},
	data () {
		return {
			columnList: null,
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			backendUrl: cmnConst.BACKEND_URL,
			errorMessage: ''
		}
	},
	props: {
		specialType: {
			type: String,
			default: ''
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('column', ['columnListData']),
		...mapGetters('common', ['userId', 'userToken', 'isAndroid', 'isWebview'])
	},
	mounted () {
		// TODO: 特集が複数掲出された場合、現状の作りだと特集A→特集Bのページ遷移で正常に表示できないため改修する必要がある(子コンポーネント化する際に合わせて改修する)
		this.getColumnListData()
	},
	beforeDestroy () {
	},
	methods: {
		// map Vuex actions
		...mapActions('column', [SET_COLUMN_LIST_GENERIC]),

		/**
		 * コラム一覧の取得処理
		 */
		getColumnListData () {
			let queryString = '&_embed&orderby=modified&order=desc&per_page=3&page=1'	// NOTE: デフォルトは最新記事3件

			// 特集毎のコラム記事出しわけ
			// TODO: 子コンポーネント化する際、子コンポーネントにcolumnListをpropsで渡すようにすればAPI通信処理をここで一元管理できそう
			switch (this.specialType) {
				case 'temperatureDifference':	// 頭痛の原因は寒暖差や空腹？
					// queryString = '&include=58,22,33'	// debug用
					queryString = '&_embed&orderby=include&order=asc&per_page=3&page=1&include=126,104,11'
					break
			}

			this.SET_COLUMN_LIST_GENERIC({
				query_string: queryString
			}).then(res => {
				if (this.columnListData.length === 0) {
					// NOTE: 該当コラムがなかったらエラーメッセージ
					this.errorMessage = '記事を取得できませんでした'
				} else {
					this.columnList = this.columnListData.map((item, index) => {
						return {
							'id': item.id,
							'title': item.title.rendered,
							'source_url': item['_embedded']['wp:featuredmedia'] ? item['_embedded']['wp:featuredmedia'][0]['source_url'] : null,
							'alt_text': item['_embedded']['wp:featuredmedia'] ? item['_embedded']['wp:featuredmedia'][0]['alt_text'] : null,
							'ratio': item['_embedded']['wp:featuredmedia'] && Number(item['_embedded']['wp:featuredmedia'][0]['media_details']['height']) > Number(item['_embedded']['wp:featuredmedia'][0]['media_details']['width']),
							'isActive': false
						}
					})
				}
			}).catch(error => {
				// エラー処理
				console.log(error)
				// NOTE: コラム取得が失敗したらエラーメッセージ
				this.errorMessage = '記事を取得できませんでした'
			}).finally(() => {
				// コンテンツの高さをemitする処理
				// CHANGED: コンテンツの高さを確保する処理の見直しのためコメントアウト
				// this.$nextTick(() => {
				// 	// TODO: 表示が崩れるため、一旦「setTimeout」で対応
				// 	setTimeout(() => {
				// 		const domContainer = document.getElementById('js_special_container')
				// 		if (domContainer) this.$emit('containerHeight', domContainer.clientHeight)
				// 	}, 1000)
				// })
			})
		}
	}
}
</script>

<style lang="scss" scoped>
@import '~swiper/css/swiper.min.css';
@import "../assets/sass/variable";

// NOTE: 次の特集で子コンポーネントに移す
.special-unique {
	&__header {
		margin: 0;
		line-height: 1;

		& > img { width: 100%; }
	}

	&__description {
		padding: 0 $spacing-10;
		margin: $spacing-20 0 0 0;
		font-size: $font-size-14;
		text-align: justify;
	}
}

.special-common {
	.about-rightnow__login--text {
		color: $text-primary;
	}

	&__back-button {
		width: 53.3334%;	// 200px
		margin: $spacing-2 auto 0;
		padding: $spacing-16 $spacing-20;
		box-sizing: border-box;
		border: solid 1px $primary;
		font-size: $font-size-14;
		text-align: center;
		line-height: $line-height-1;
		background: $primary;
		color: $text-seconday;

		&--wrapper {
			padding-top: $spacing-16;
			box-sizing: border-box;
		}
	}
}
</style>
